import React from 'react'

import { GridList, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Placeholder } from '../../components/Placeholder'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="GridList"
    components={[{ component: GridList }]}
  >
    <Section>
      <Paragraph>
        <Code>GridList</Code> tuottaa semanttisesti listan.
      </Paragraph>
      <Playground>
        <GridList
          gap="sm"
          sm={2}
          md={3}
          noMargin
        >
          {[...Array(9).keys()].map(i => (
            <Placeholder
              block
              key={i}
            />
          ))}
        </GridList>
      </Playground>
      <Playground format="html">
        <ul className="grid-list grid-list-sm-2 grid-list-md-3 grid-list-gap-sm mb-0">
          {[...Array(9).keys()].map(i => (
            <li
              className="grid-list-item"
              key={i}
            >
              <Placeholder block />
            </li>
          ))}
        </ul>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Code>GridList</Code>-komponenttia ei käytetä, mikäli korteissa on
      otsikoita. Ruudunlukijalla luettaessa listan sisällä olevat otsikot
      tekevät siitä hankalasti hahmotettavan. Käytä sen sijaan{' '}
      <Code>Container</Code>-, <Code>Row</Code>- ja <Code>Col</Code>
      -komponentteja.
    </Section>
  </Content>
)

export default Page
